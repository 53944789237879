body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: 'adoba only';
  src: url(assets/fonts/adoba\ Only.ttf);
}

@font-face {
  font-family: 'DINPro';
  src: url(assets/fonts/DINPro.ttf);
}

@font-face {
  font-family: 'noto';
  src: url(assets/fonts/noto-sans-kr-v27-korean-regular.woff);
}